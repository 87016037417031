<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>상황별 발생 추이(본사)</b>
		</div>
		<!-- Search -->
		<div ref="panelFlat" class="panel panel-flat">
			<div class="panel-body" style="width: 1300px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row" style="padding-left: 10px; padding-bottom: 20px">
						<input type="radio" name="view-options" value="time" v-model="viewOption" style="margin-right: 5px" />
						<span style="font-size: 13px">시간별 발생 평균 보기</span>
						&nbsp;&nbsp;
						<input type="radio" name="view-options" value="date" v-model="viewOption" style="margin-right: 5px" />
						<span style="font-size: 13px">날짜별 발생 건수 보기</span>
					</div>
					<div class="row">
						<div>
							<div class="form-group" style="padding-left: 10px">
								<!-- 현장 -->
								<label style="padding-right: 26px">{{ detailsFieldMap.siteId }}</label>
								<select2 v-model="searchVM.siteId" :disabled="isSiteDisabled" multiple="multiple">
									<option value="">전체</option>
									<option v-for="item in searchOptions.siteOptions" :key="item.siteId" :value="item.siteId">
										{{ item.siteNm }}
									</option>
								</select2>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-2" style="width: 300px">
							<div class="form-group">
								<!-- 발생구분 -->
								<label>{{ detailsFieldMap.eventType }}</label>
								<select2 v-model="searchVM.eventType">
									<option value="">전체</option>
									<option v-for="item in searchOptions.eventTypeOptions" :key="item.value" :value="item.value">
										{{ item.text }}
									</option>
								</select2>
							</div>
						</div>
						<div class="col-md-2" style="width: 300px">
							<div class="form-group">
								<!-- CCTV -->
								<label style="padding-right: 19px">{{ detailsFieldMap.cctvInstallPlaceCont }}</label>
								<select2 v-model="searchVM.cctvInstallPlaceCont">
									<option value="">전체</option>
									<option v-for="item in searchOptions.cctvOptions" :key="item.cctvInstallPlaceCont" :value="item.cctvInstallPlaceCont">
										{{ item.cctvInstallPlaceCont }}
									</option>
								</select2>
							</div>
						</div>
						<div class="col-md-1" style="width: 100px; margin-left: -60px; margin-right: 40px">
							<div class="form-group">
								<label style="margin-top: 8px; padding-right: 7px">이력</label>
								<div class="checkbox" style="margin-bottom: 14px">
									<input type="checkbox" id="check" v-model="searchVM.isCheckedCctvAll" />
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-5" period style="width: 750px">
							<!-- 요청일자 -->
							<div class="form-group" period>
								<label style="margin-right: 10px">{{ detailsFieldMap.period }}</label>
								<input type="date" class="form-control" style="margin-right: 5px" v-model="searchVM.periodStrtDt" :format="'yyyy-MM-dd'" />
								~
								<input
									type="date"
									class="form-control"
									style="margin-left: 10px"
									v-model="searchVM.periodEndDt"
									:format="'yyyy-MM-dd'"
									:min="searchVM.periodStrtDt"
									:max="maxDay"
								/>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div id="detailsChart" class="row">
			<div class="col-lg-12">
				<!-- 1. 일별 API 호출(최근N일) -->
				<div class="panel panel-flat">
					<div class="panel-heading">
						<h6 class="panel-title">
							<i class="icon-stats-growth position-left"></i>
							<b>라인 차트</b>
						</h6>
					</div>
					<div class="panel-body">
						<div id="lineChart" style="height: 700px"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Select2 from '@/components/Select2';
import moment from 'moment';
import apiIndex from '../../api/index';
const dangerOccurUrl = apiIndex.dangerOccur;
const objectDangerOccurStatistic = apiIndex.objectDangerOccurStatistic;

let axiosExtention;

export default {
	components: {
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		searchVM: {
			siteId: [],
			eventType: '',
			cctvInstallPlaceCont: '',
			periodStrtDt: '',
			periodEndDt: '',
			searchSiteLength: 1, //선택된 현장 개수
			isCheckedCctvAll: false, //CCTV 전체 선택 여부
		},
		searchOptions: {
			siteOptions: [],
			eventTypeOptions: [],
			cctvOptions: [],
		}, // 검색 select options
		detailsFieldMap: {
			siteId: '현장',
			eventType: '발생구분',
			cctvInstallPlaceCont: 'CCTV',
			period: '요청일자',
		},
		chartJson: [],
		chartObjectTargetList: [],
		isSiteDisabled: false,
		maxDay: '', //periodEndDt의 지정 가능한 최대 일자
		viewOption: 'time',
	}),
	computed: {
		//차트
		// API 호출 통계
		charData() {
			return {
				// 차트 x 축 데이터
				dataSource: [],
				// X축
				categoryAxis: {
					// 데이터를 가져올 필드명 세팅
					field: 'dateData',
					// rotation: 화면이 작아졌을 때 세로로 보여줄 거냐..
					// foramt: 값을 보여줄 형태 지정
					// step: 값이 몇 씩 증가하는가..
					labels: { rotation: 'auto', step: 1, font: '10px Arial, Helvetica, sans-serif' },
				},
				// Y축
				valueAxis: [
					// format: y축 데이터를 어떻게 표시할 것인가..
					{
						labels: {
							template: `#if (value == null || value < 1000) {# #= kendo.format('{0}', value) # #} else {# #= kendo.format('{0:N0}', value) # #}#`,
							font: '11px Arial, Helvetica, sans-serif',
						},
					},
				],
				// 툴팁( 마우스 올렸을 때 나오는 조그만 정보 창 )
				tooltip: {
					// 툴팁 표시, 비표시 설정
					visible: true,
					// 툴팁 내용을 어떻게 표시할 것인가..
					template: `#: category #<br/>#: series.name # : #: kendo.toString(value, 'n1') #건`,
				},
				// 차트 원본 데이터(json 배열) 세팅
				series: [],
			};
		},
	},
	watch: {
		chartJson() {
			//details활성화 시 스크롤 이동처리
			this.$nextTick(function () {
				window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
			});
		},
		'searchVM.siteId': {
			handler(newVal, oldVal) {
				if (oldVal[0] !== '' && newVal[0] === '') {
					// 전체를 선택한 경우 나머지 현장 다 지우기
					this.$nextTick(function () {
						this.searchVM.siteId.splice(1, this.searchVM.siteId.length - 1);
					});
				} else if (oldVal[0] === '' && this.searchVM.siteId.length > 1) {
					// 전체가 있는 상황에서 특정 현장을 선택한 경우, 전체를 지우기
					this.$nextTick(function () {
						this.searchVM.siteId.splice(0, 1);
					});
				}
			},
		},
		'searchVM.isCheckedCctvAll': {
			handler() {
				if (this.searchVM.siteId.length == 1 && this.searchVM.siteId[0] != '') {
					this.$axios
						.post(dangerOccurUrl.inqDangerOccurCctvInfo, { siteId: this.searchVM.siteId[0], isCheckedCctvAll: this.searchVM.isCheckedCctvAll })
						.then(
							function (r) {
								this.searchOptions.cctvOptions = r.data;
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.periodStrtDt': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('objectDangerOccurStatisticLine2PageParam'));
		if (!this.pageParam) {
			location.href = apiIndex.mainUrl;
		}

		axiosExtention = this.$jarvisExtention.axiosExtention;

		// 본사관리자가 아니면, 현장 select box Disabled
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		this.searchVM.siteId = [this.pageParam.loginUserSiteId];
		this.searchVM.cctvInstallPlaceCont = '';
		this.searchVM.periodStrtDt = moment().add(-6, 'day').format('YYYY-MM-DD');
		this.searchVM.periodEndDt = moment().format('YYYY-MM-DD');

		this.searchOptions.siteOptions = this.pageParam.siteOptions;
		this.searchOptions.cctvOptions = this.pageParam.cctvOptions;
		this.searchOptions.eventTypeOptions = [
			{
				value: 'danger',
				text: '위험',
			},
			{
				value: 'warnWork',
				text: '주의',
			},
		];

		this.chartObjectTargetList = this.pageParam.chartObjectTargetList;
	},
	mounted() {
		this.search();
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('lineChart');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		//유효한 요청일자 기간인지 확인
		isValidPeriod() {
			// 날짜 값만 확인
			if (this.searchVM.periodStrtDt == null || this.searchVM.periodEndDt == null) {
				alert('날짜를 입력해주세요');
				return false;
			}

			if (new Date(this.searchVM.periodStrtDt) > new Date(this.searchVM.periodEndDt)) {
				alert('유효한 날짜를 입력해주세요');
				return false;
			}

			return true;
		},
		search() {
			if (this.isValidPeriod() == false) {
				return;
			}

			if (this.searchVM.siteId.length < 1) {
				return alert('현장을 선택해 주세요');
			}

			// 데이터 초기화
			this.resetChartData();

			if (this.viewOption == 'time') {
				// 시간별 발생 평균 차트 데이터 불러오기
				this.loadLineChartByTheHourData();
			} else if (this.viewOption == 'date') {
				// 날짜별 발생 건수 차트 데이터 불러오기
				this.loadLineChartByDateData();
			} else {
				console.log('Invalid view option', this.viewOption);
			}
		},
		// 데이터 초기화
		resetChartData() {
			this.chartJson = [];
			this.charData.dataSource = [];
			this.charData.series = [];
		},
		// 시간별 발생 평균 차트 데이터 불러오기
		loadLineChartByTheHourData() {
			this.$axios
				.post(objectDangerOccurStatistic.inqObjectDangerOccurStatisticLineByTheHour, this.searchVM)
				.then(response => {
					this.chartJson = response.data;
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 날짜별 발생 건수 차트 데이터 불러오기
		loadLineChartByDateData() {
			this.$axios
				.post(objectDangerOccurStatistic.inqObjectDangerOccurStatisticLineByDate, this.searchVM)
				.then(response => {
					this.chartJson = response.data;
					this.createChartData(response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 불러온 데이터 그래프 형식에 맞게 parsing
		async createChartData(data) {
			this.chartObjectTargetList = await this.$axios
				.post(objectDangerOccurStatistic.inqChartObjectTargetList, {
					eventType: this.searchVM.eventType,
				})
				.then(res => res.data);

			// 대상물-조치대상 개수
			let listSize = this.chartObjectTargetList.length;
			// 대상물-조치대상 개수 리스트
			let dangerOccurCntList = [];
			for (let i = 0; i < listSize; i++) {
				let list = [];
				dangerOccurCntList.push(list);
			}

			data.forEach(d => {
				let date = d.dateData.split(' ');

				// 날짜별 발생 건수 보기 일 때 x축 값 조정
				if (this.viewOption == 'date') {
					if (date) {
						if (date[1] != '00:00:00') {
							// 0시가 아니면 시간만 표현
							d.dateData = date[1];
						} else {
							// 0시면 날짜로 표현
							d.dateData = date[0];
						}
					}
				}

				// 날짜
				this.charData.dataSource.push({ dateData: d.dateData });
				// 대상물-조치대상 개수를 각 번호에 쭉 push
				for (let i = 0; i < listSize; i++) {
					dangerOccurCntList[i].push(d.objectTargetDangerCnts[i]);
				}
			});

			let series = [];

			// 그래프 세팅
			for (let i = 0; i < listSize; i++) {
				let line = {
					type: 'line',
					data: dangerOccurCntList[i],
					name: this.chartObjectTargetList[i].objectNm + '\n' + `${this.chartObjectTargetList[i].targetNm || ''}`,
				};
				series.push(line);
			}

			for (let i = 0; i < listSize; i++) {
				this.charData.series.push(series[i]);
			}

			this.drawChart();
		},
		// 차트 그리기
		drawChart() {
			$('#lineChart').kendoChart({
				dataSource: this.charData.dataSource,
				categoryAxis: this.charData.categoryAxis,
				valueAxis: this.charData.valueAxis,
				series: this.charData.series,
				legend: {
					position: 'right',
				},
				tooltip: this.charData.tooltip,
			});
		},
	},
};
</script>

<style>
span.select2-selection.select2-selection--single[aria-disabled='true'] {
	background: #eee;
}
.checkbox {
	display: inline-block !important;
	margin-left: 0.7em;
}

.row .form-group > span {
	width: 60% !important;
	margin-left: 10px;
}
.form-group input {
	display: inline-block;
}

[type='search'] {
	display: inline-block;
	width: 180px;
	margin-right: 5px;
}

[period] [type='date'] {
	width: 168px !important;
}

.col-lg-12 {
	padding-right: 0 !important;
}
</style>
